
import { defineComponent, ref } from '@vue/composition-api'
import CommonScrollButton from '@/components/common/CommonScrollButton.vue'

import AppBar from './AppBar.vue'

export default defineComponent({
  name: 'DefaultLayout',
  components: { AppBar, CommonScrollButton },
  setup() {
    return {
      isNavOpen: ref(false),
    }
  },
})
