
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { mdiChevronUp } from '@mdi/js'

export default defineComponent({
  name: 'CommonScrollButton',

  setup: () => {
    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    const isButtonVisible = ref(false)

    window.addEventListener('scroll', () => {
      isButtonVisible.value = window.scrollY > 100
    })

    return reactive({
      icons: {
        mdiChevronUp,
      },
      state: {
        isButtonVisible,
      },
      functions: {
        scrollToTop,
      },
    })
  },
})
