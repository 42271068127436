var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AppBar', {
    attrs: {
      "is-nav-open": _vm.isNavOpen
    },
    on: {
      "update:isNavOpen": function updateIsNavOpen($event) {
        _vm.isNavOpen = $event;
      },
      "update:is-nav-open": function updateIsNavOpen($event) {
        _vm.isNavOpen = $event;
      }
    }
  }), _c('v-main', [_c('router-view', {
    key: _vm.$route.path
  }), _c('CommonScrollButton')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }